<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-flex-between uk-margin">
            <div class="uk-flex uk-flex-left">
                <div class="uk-inline">
                    <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                    <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'name')" type="text" placeholder="Search community name">
                </div>
                <div class="uk-margin-left">
                    <div class="uk-form-controls uk-width-small">
                        <select class="uk-select" id="form-horizontal-select" @change="(e) => changeMeta(e, 'category')">
                            <option :value="''">Select category</option>
                            <template v-for="(category, x) in categories" >
                                <option :value="category" :key="x">{{category}}</option>
                            </template>
                        </select>
                    </div>
                </div>
            </div>
            <div class="uk-flex uk-flex-right">
                <button class="uk-button uk-button-primary uk-border-rounded" type="button" @click="showModal('mainModal')">Create</button>
            </div>
        </div>
        <div class="uk-card uk-card-default">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr class="uk-text-left">
                            <th>Name</th>
                            <th>Category</th>
                            <th>URL</th>
                            <th class="uk-width-auto uk-text-center">Action</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="6"/>
                    <tbody v-else-if="page_data.docs.length>0">
                        <tr v-for="(data, i) in page_data.docs" :key="i" class="uk-text-left">
                            <td>{{preview(data.name)}}</td>
                            <td>{{preview(data.category)}}</td>
                            <td>{{preview(data.url)}}</td>
                            <td class="uk-text-center">
                                <button class="uk-button uk-button-small uk-button-default" type="button" >Actions</button>
                                <div uk-dropdown="mode: click">
                                    <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                        <li><a @click="showModal('mainModal', data, true)">Edit</a></li>
                                        <li class="uk-nav-divider"></li>
                                        <li><a @click="showModal('deleteModal', data)">Delete</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="4"/>
                </table>
            </div>
            <pagination
                :total-data="page_data.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>
        <div id="mainModal" uk-modal esc-close="false" bg-close="false">
          <div class="uk-modal-dialog">
            <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('mainModal')"></button>
            <div class="uk-modal-header">
              <h2 v-if="isEdit" class="uk-modal-title">Edit Form</h2>
              <h2 v-else class="uk-modal-title">Create Form</h2>
            </div>
            <div class="uk-modal-body">
              <div class="uk-margin">
                <label class="uk-form-label">Name <b class="uk-text-danger">*</b></label>
                <input
                    class="uk-input"
                    :class="{'uk-form-danger': errors.has('name')}"
                    name="name"
                    type="text"
                    v-model="form.name"
                    v-validate="'required'">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('name')">{{ errors.first('name') }}</span>
              </div>
              <div class="uk-margin">
                <label class="uk-form-label">Category <b class="uk-text-danger">*</b></label>
                <multiselect
                  v-model="form.category"
                  placeholder="Select Category"
                  name="category"
                  :options="categories"
                  v-validate="'required'">
                  </multiselect>
                <span class="uk-text-small uk-text-danger" v-show="errors.has('category')">{{ errors.first('category') }}</span>
              </div>
              <div class="uk-margin">
                <label class="uk-form-label">URL <b class="uk-text-danger">*</b></label>
                <input
                    class="uk-input"
                    :class="{'uk-form-danger': errors.has('url')}"
                    name="url"
                    type="text"
                    v-model="form.url"
                    v-validate="'required'">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('url')">{{ errors.first('url') }}</span>
              </div>
            </div>
            <div class="uk-modal-footer uk-text-right">
              <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
              <button v-else class="uk-button uk-button-primary uk-border-rounded" type="button" @click="sendForm">Save</button>
            </div>
          </div>
        </div>
        <div id="deleteModal" class="uk-flex-top" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('deleteModal')"></button>
                <div class="uk-modal-header"><b>Are you sure to delete this data ?</b></div>
                <div class="uk-modal-body" >
                    <span>Name: {{form.name}}</span><br>
                    <span>Category: {{form.category}}</span><br>
                    <span>URL: {{form.url}}</span>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
                    <button v-else class="uk-button uk-button-danger uk-border-rounded" type="button" @click="deleteData">Delete</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LoadingTable from '@/components/globals/tables/Loading';
import Pagination from '@/components/globals/Pagination';
import { PREFIX_IMAGE } from '@/utils/constant';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';

export default {
    data(){
        return {
            images: PREFIX_IMAGE,
            meta: {
                limit: 50,
                page: 1,
                name: '',
                category: ''
            },
            form: {
                name: '',
                category: '',
                url: ''
            },
            page_data: {docs: [], totalDocs: 0},
            isLoading: true,
            isEdit: false,
            categories: ['Whatsapp', 'Facebook', 'Telegram', 'Instagram', 'LinkedIn']
        };
    },
    components: {
        EmptyTable,
        Pagination,
        LoadingTable
    },
    watch: {
        meta() {
            this.getInitPageData();
        },
    },
    async mounted() {
        await this.getInitPageData();
    },
    methods: {
        ...mapActions({
            getCommunity: 'marketing/getCommunity',
            showCommunity: 'marketing/showCommunity',
            deleteCommunity: 'marketing/deleteCommunity',
            createCommunity: 'marketing/createCommunity',
            updateCommunity: 'marketing/updateCommunity'
        }),
        async getInitPageData(){
            this.isLoading = true;
            this.page_data = await this.getCommunity(this.meta);
            this.isLoading = false;
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        preview(data) {
            if (data == null) {
                return '-';
            }
            if (data.length > 26) {
                return data.substring(0,26)+'...';
            } else {
                return data;
            }
        },
        resetForm() {
            this.form = {
                name: '',
                category: '',
                url: ''
            };
            this.isEdit = false;
            this.$validator.reset();
        },
        async showModal(modal, data=null, edit=false){
            switch (modal) {
            case 'mainModal':
                if (data) {
                    this.form.name = data.name;
                    this.form.category = data.category;
                    this.form.url = data.url;
                }
                if (edit) {
                    this.form.id = data._id;
                    this.isEdit = true;
                }
                await window.UIkit.modal('#mainModal').show();
                break;
            case 'deleteModal':
                if (data) {
                    this.form = data;
                }
                await window.UIkit.modal('#deleteModal').show();
                break;
            }
        },
        async hideModal(modal){
            switch (modal) {
            case 'mainModal':
                await window.UIkit.modal('#mainModal').hide();
                this.resetForm();
                break;
            case 'deleteModal':
                await window.UIkit.modal('#deleteModal').hide();
                this.resetForm();
                break;
            }
        },
        async sendForm() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;

                const response = this.isEdit
                    ? await this.updateCommunity(this.form)
                    : await this.createCommunity(this.form);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Saved!');
                    this.getInitPageData();
                    this.hideModal('mainModal');
                } else {
                    notificationDanger('Failed to save!');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async deleteData() {
            try {
                this.isLoading = true;

                const response = await this.deleteCommunity(this.form._id);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Deleted!');
                    this.resetForm();
                    this.getInitPageData();
                    this.hideModal('deleteModal');
                } else {
                    notificationDanger('Failed to delete!');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
    },
};
</script>
